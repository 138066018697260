import { Grid, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { NavbarRef } from "../../constants/navbarRef";


const Footer = () => {

  const scrollToRef = (ref) => {
    window.scrollTo({
        top: ref.current.offsetTop,
        behavior: "smooth"
    });
};

  return (
    <div style={{ backgroundColor: '#C4E6FB', margin: '0px 8%' }}>
      <div style={{ borderTopRightRadius: '100px', backgroundColor: '#F9F9F9' }}>
        <Grid container padding='20px 40px' margin='0px 60px'>
          <Grid item lg={4} md={4} sm={4} xs={6}>
            <div>
              <div style={{ height: '100px', width: '140px', }}>
                <img src="/images/logo.png" style={{ height: '100%', width: '100%', objectFit: 'contain' }} />
              </div>
              <Typography variant="p16_700" fontSize='16px' fontWeight='700'> Connect with us </Typography>
              <div style={{ padding: '20px 0' }}>
                <a href="https://www.youtube.com/@GrowRetail" target="_blank" rel="noreferrer">
                  <YouTubeIcon style={{ padding: '5px 5px 5px 0px', color: 'black' }} />
                </a>
                <a href="https://www.facebook.com/people/GrowRetail-India/61558400826509/" target="_blank" rel="noreferrer">
                  <FacebookRoundedIcon style={{ padding: '5px', color: '#0B579E' }} />
                </a>
                <a href="#/" target="_blank" rel="noreferrer">
                  <InstagramIcon style={{ padding: '5px', color: 'black' }} />
                </a>
              </div>
            </div>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={6} paddingTop='20px'>
            <div>
              <Typography variant="p16_700" fontSize='16px' fontWeight='700'> Quick Links </Typography>
              <Grid container paddingTop='20px'>
                <Grid item lg={4} md={4} sm={4} xs={4}>
                  <div style={{cursor: 'pointer'}} onClick={() => {
                    scrollToRef(NavbarRef.AboutUs)
                  }}>
                  <Typography color='#424242' fontSize='16px' fontWeight='400'> About Us </Typography>
                  </div>
                  <div style={{cursor: 'pointer'}} onClick={() => {
                    scrollToRef(NavbarRef.GrowRetail)
                  }}>
                  <Typography color='#424242' fontSize='16px' fontWeight='400'> How it works </Typography>
                  </div>
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={4}>
                <div style={{cursor: 'pointer'}} onClick={() => {
                    scrollToRef(NavbarRef.FAQ)
                  }}>
                  <Typography color='#424242' fontSize='16px' fontWeight='400'> FAQ </Typography>
                  </div>
                  <div style={{cursor: 'pointer'}} onClick={() => {
                    scrollToRef(NavbarRef.PlaystoreAppstore)
                  }}>
                  <Typography color='#424242' fontSize='16px' fontWeight='400'> Playstore & Appstore </Typography>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={6} paddingTop='20px'>
            <div>
              <Typography variant="p16_700" fontSize='16px' fontWeight='700'> Contact us </Typography>
              <div style={{ paddingTop: '20px' }}>
                <Typography color='#424242'> 8/65, First Floor, Radhakrishnan Street, </Typography>
                <Typography color='#424242'>  Velachery, Chennai - 600042. </Typography>
              </div>
              <div style={{ paddingTop: '20px' }}>
                <Typography variant="p16_700" fontSize='16px' fontWeight='700'> Phone: <span style={{ fontWeight: 400 }}> 9840298435 </span> </Typography>
                <br />
                <Typography variant="p16_700" fontSize='16px' fontWeight='700'> Email: <span style={{ fontWeight: 400 }}> sales@growretail.com </span> </Typography>

              </div>
            </div>
          </Grid>
        </Grid>
        <div style={{backgroundColor: 'black', color: 'white', display: 'flex', justifyContent: 'center', alignContent:'center'}}>
        <ul>
        <a style={{color:'white'}} href="https://erp.tazk.in/growretail/PrivacyPolicy.html" target="_blank" rel="noreferrer"> Privacy Policy </a>
        </ul>
        <ul>
        <a style={{color:'white'}} href="https://erp.tazk.in/growretail/Terms&conditions.html" target="_blank" rel="noreferrer"> Terms & Condition </a>
        </ul>
      </div>
      </div>
    </div>
  );
};

export default Footer;
