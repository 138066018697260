import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "../pages/HomePage";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const RoutesComp = () => {
    return (
        <>
        <BrowserRouter>
            <Navbar />
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/about" element={<div>test</div>} />
            </Routes>
            <Footer />
        </BrowserRouter>
      </>
    )
}

export default RoutesComp;