import { Grid, Typography } from '@mui/material'
import React from 'react'

export default function Qr() {
    return (
        <div style={{ backgroundColor: '#C4E6FB' }}>
            <Grid container padding='60px 60px' style={{ backgroundColor: 'white', borderTopRightRadius: '100px', borderBottomLeftRadius: '100px' }}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Typography style={{ color: '#0A84FF', fontSize: '16px', fontWeight: '500' }}> GrowRetail </Typography> <br />
                    <Typography style={{ color: 'black', fontSize: '40px', fontWeight: 300 }}> Join the <span style={{ fontSize: '46px', fontWeight: 600 }}> GrowRetail <br/>
                        Community Today! </span> </Typography>
                    <img src="/images/qr.png" alt=''/>
                    <Typography style={{ paddingTop: '20px', fontSize: '16px', fontWeight: 400 }}>Encourage mobile shop owners to take action by <br/> scheduling a personalized demo, exploring partnership <br/> opportunities, and connecting with us on social media <br/> for updates and insights.</Typography>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <div>
                    <img src="/images/maskgroup.png" alt='' style={{height: '100%', width: '100%'}}/>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}
