import { Grid, Typography } from '@mui/material'
import React from 'react'

export default function GrowRetail() {
    return (
        <div style={{ backgroundColor: '#C4E6FB' }}>
            <div style={{ borderBottomRightRadius: '100px', borderTopLeftRadius: '100px', backgroundColor: 'white', paddingTop: '60px' }}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Grid container>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography style={{ color: '#0A84FF', fontSize: '16px', fontWeight: '500', display: 'flex', justifyContent: 'center' }}> Why GrowRetail </Typography> <br />
                            <Typography style={{ color: 'black', fontSize: '40px', fontWeight: 300, display: 'flex', justifyContent: 'center' }}> Empowering <span style={{ fontSize: '46px', fontWeight: 600 }}> Mobile Retail </span> </Typography>
                            <Typography style={{ fontSize: '46px', fontWeight: 600, display: 'flex', justifyContent: 'center' }}> Connections </Typography>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Grid container margin='20px 40px'>
                                <Grid item lg={4} md={4} sm={12} xs={12} display='flex' justifyContent='center'>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '20px' }}>
                                            <img src="/images/first.png" alt="Phone Buy" />
                                        </div>
                                        <div>
                                            <Typography style={{ fontSize: '24px', fontWeight: 600, display: 'flex', justifyContent: 'center' }}> Reach More Customers </Typography>
                                            <Typography style={{ fontSize: '16px', fontWeight: 400, display: 'flex', justifyContent: 'center' }}> Your products and Offers will be available to <br />customers via the LocSto App. Customers within <br />
                                                a 15km radius will visit your store. </Typography>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item lg={4} md={4} sm={12} xs={12} display='flex' justifyContent='center'>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '20px' }}>
                                            <img src="/images/second.png" alt="Phone Buy" />
                                        </div>
                                        <div>
                                            <Typography style={{ fontSize: '24px', fontWeight: 600, display: 'flex', justifyContent: 'center' }}> Capture Nearby Customers! </Typography>
                                            <Typography style={{ fontSize: '16px', fontWeight: 400, display: 'flex', justifyContent: 'center' }}> You will receive inquiries from nearby customers, <br /> increasing your customer walk-ins. </Typography>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item lg={4} md={4} sm={12} xs={12} display='flex' justifyContent='center'>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '20px' }}>
                                            <img src="/images/third.png" alt="Phone Buy" />
                                        </div>
                                        <div>
                                            <Typography style={{ fontSize: '24px', fontWeight: 600, display: 'flex', justifyContent: 'center' }}> Enhance Availability </Typography>
                                            <Typography style={{ fontSize: '16px', fontWeight: 400, display: 'flex', justifyContent: 'center' }}> If you wish, you can share your products with <br /> nearby stores, and vice versa. Never say <br />
                                                "no stock" to customers. </Typography>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>

                <Grid container>

                </Grid>
            </div>
        </div>
    )
}
