import logo from './logo.svg';
import './App.css';
import { Button } from '@mui/material';
import RoutesComp from './routes/Routes';

function App() {
  return (
    <RoutesComp />
  );
}

export default App;
