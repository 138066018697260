import React, { useRef } from "react";
import Banner from "../../components/Home/banner";
import AboutUs from "../../components/Home/aboutUs";
import GrowRetail from "../../components/Home/growRetail";
import Qr from "../../components/Home/qr";
import FAQ from "../../components/Home/faq";
import { NavbarRef } from "../../constants/navbarRef";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CallIcon from '@mui/icons-material/Call';

const HomePage = () => {

    return (
        <>
        <div style={{ margin: '0px 8%' }}>
            <Banner/>
            <div ref={NavbarRef.AboutUs}><AboutUs/></div>
            <div ref={NavbarRef.GrowRetail}><GrowRetail/></div>
            <div ref={NavbarRef.PlaystoreAppstore}><Qr/></div>
            <div ref={NavbarRef.FAQ}><FAQ/></div>
            <div ref={NavbarRef.ContactUs}></div>
            <nav>
            </nav>
        </div>
        <div style={{ position: 'fixed', bottom: 0, right:0, padding: '60px 30px'}}>
        <div style={{width: '45px', height: '45px', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10px',backgroundColor: '#66BB6A'}}>
        <a style={{color:'white'}} href="tel:+91-9840298435">
            <CallIcon/>
            </a>
            </div>
            <div style={{width: '45px', height: '45px', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10px',backgroundColor: '#25D366'}}>
            <a style={{color:'white'}} href="https://wa.me/+919840298435">
            <WhatsAppIcon/>
            </a>
            </div>
        </div>
        </>
    )
}

export default HomePage;