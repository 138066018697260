import React, { useMemo } from "react";
import { NavbarRef } from "../../constants/navbarRef";

const Navbar = () => {
    const menuArr = useMemo(
        () => [
            { id: 1, menuName: 'Home', targetId: 'home-section' },
            { id: 2, menuName: 'About Us', targetId: 'about-us-section', ref: NavbarRef.AboutUs },
            { id: 4, menuName: 'How it Works', targetId: 'how-it-works-section', ref: NavbarRef.GrowRetail },
            { id: 5, menuName: 'FAQ', targetId: 'faq-section', ref: NavbarRef.FAQ },
            { id: 6, menuName: 'Playstore & Appstore', targetId: 'playstore-appstore-section', ref: NavbarRef.PlaystoreAppstore },
            { id: 7, menuName: 'Contact Us', targetId: 'contact-us-section', ref: NavbarRef.ContactUs  },
        ],
        [],
    );

    const scrollToRef = (ref) => {
      window.scrollTo({
          top: ref.current.offsetTop,
          behavior: "smooth"
      });
  };
  

    return (
        <div style={{ width: '100%', backgroundColor: 'white', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ height: '100px', width: '140px', marginLeft: '4%', cursor: 'pointer' }}>
                <img src="/images/favicon.png" title="GrowRetail" style={{ height: '100%', width: '100%', objectFit: 'contain' }} alt="Logo" />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', marginRight: '5%', alignItems: 'center' }}>
                {menuArr.map((menuItem) => (
                    <div style={{ marginLeft: '10px', padding: '10px', cursor: 'pointer' }} key={menuItem.id} onClick={() => scrollToRef(menuItem.ref)}>
                        {menuItem.menuName}
                    </div>
                ))}
            </div>
            <div></div>
        </div>
    )
}

export default Navbar;