import { Grid, Typography } from '@mui/material'
import React from 'react'

export default function AboutUs() {
  return (
    <div style={{backgroundColor: '#C4E6FB'}}>
    <div style={{borderBottomLeftRadius: '100px', backgroundColor: 'white'}}>
      <Grid container padding='30px'>
       <Grid item lg={6} md={6} sm={6} xs={6}>
       <Typography style={{color: '#0A84FF',fontSize: '16px', fontWeight: '500'}}> ABOUT US </Typography>
       <div style={{paddingTop: '30px'}}>
       <Typography style={{color: 'black', fontSize: '40px', fontWeight: 300}}> Introducing GrowRetail </Typography>
       <Typography style={{color: 'black', fontSize: '46px', fontWeight: 600}}> Empowering Mobile <br/> Shop Owners </Typography>
       </div>
       <div style={{paddingTop: '15px'}}>
        <Typography fontSize='24px' fontWeight='400'> Welcome to GrowRetail, ultimate solution for <br/> revolutionizing the mobile retail experience. </Typography>
       </div>
       <div style={{paddingTop: '15px'}}>
        <Typography style={{fontSize: '16px', fontWeight: '400'}}> We connect mobile shop owners to a dynamic network of peers, <br/> enhancing product availability and customer satisfaction. </Typography>
       </div>
       </Grid>
       <Grid item lg={6} md={6} sm={6} xs={6}>
       <img src="/images/rectangle.png" alt="Phone Buy" />
       <img src="/images/16k.png" style={{position:'absolute', right:'440px', top:'1140px'}}/>
       <img src="/images/heart.png" style={{position:'absolute', right:'885px', top:'900px'}}/>
       </Grid>
      </Grid>
    </div>
    </div>
  )
}
