import { Grid, Typography } from '@mui/material'
import React from 'react'

export default function Banner() {
  return (
    <div style={{ backgroundColor: '#0A2D66', borderBottomLeftRadius: '50px'}}>
     <Grid container>
     <Grid item lg={6} md={6} sm={12} xs={12} padding= '80px 30px'>
      <div style={{color: 'white', padding:'20px'}}>
      <Typography fontSize='30px'>
      Join GrowRetail today & <br/> unlock the full potential <br/> of your retail business!
      </Typography>
      {/* <Typography fontSize='30px'>
        integer eget aliqueted
      </Typography> */}
      </div>
      <div style={{color: 'white', padding:'20px'}}>
        <Typography>
        Our platform connects retail shops like you, empowering you to grow your business, reach more customers, and maximize your potential.
        </Typography>
        {/* <Typography>
          morbi tristique senectus et netus. Customize
        </Typography> */}
      </div>
      </Grid>
     <Grid item lg={6} md={6} sm={12} xs={12} padding='20px' style={{position: 'relative'}}>
     <img src="/images/man.png" alt=''/>
     <img src="/images/16k.png" alt='' style={{position:'absolute', right:'300px', top:'200px'}}/>
     <img src="/images/followers.png" alt='' style={{position:'absolute', left:'2%', top:'45%'}}/>
     </Grid>
     </Grid>
    </div>
  )
}
