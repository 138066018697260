import { Button, Divider } from '@mui/material';
import React, { useState } from 'react';
import EastIcon from '@mui/icons-material/East';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function Dropdown({ question, answer }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      style={{
        marginBottom: '20px',
        fontFamily: 'Arial, sans-serif',
        fontSize: '16px',
      }}
    >
      <div
        style={{
          padding: '10px',
          borderRadius: '5px',
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          fontSize: '20px',
          fontWeight: 600
        }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{question}</span>
        <span>{isOpen ? <KeyboardArrowUpIcon style={{color:'#0A84FF'}}/> : <KeyboardArrowDownIcon style={{color:'#0A84FF'}}/>}</span>
      </div>
      {isOpen && (
        <div
          style={{
            backgroundColor: '#fff',
            padding: '10px',
            marginTop: '5px',
            borderRadius: '5px',
          }}
        >
          <p>{answer}</p>
        </div>
      )}
    </div>
  );
}

function Faq() {
  return (
    <div style={{ backgroundColor: '#C4E6FB',borderBottomRightRadius: '100px' }}>
    <div style={{ backgroundColor: '#C4E6FB' }}>
    <div style={{ backgroundColor: 'white', borderTopLeftRadius: '100px', borderBottomRightRadius: '100px', display: 'flex', justifyContent: 'center' }}>
    <div style={{ width: '70%', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
      <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>
      Frequently Asked Questions
      </h1>
      <Dropdown
        question="What is GrowRetail"
        answer="GrowRetail is a mobile application which developed to support and empower retail business."
      />
      <Divider/>
      <Dropdown
        question="Why GrowRetail"
        answer="Never say “No Stock” to customers.
        Grab online shoppers to visit your store."
      />
      <Divider/>
      <Dropdown
        question="How GrowRetail app works"
        answer="Your products and Offers will be available to 
        customers via the LocSto App. Customers within 
        a 15km radius will visit your store.
        You will receive inquiries from nearby customers, 
        increasing your customer walk-ins.
        If you wish, you can share your products with 
        nearby stores, and vice versa. Never say 
        `no stock` to customers."
      />
      <Divider/>
      <Dropdown
        question="How to register in GrowRetail"
        answer="Download the app from the Play Store or Apple Store, complete a simple signup form, take a photo of your shop with the glowing signboard, and submit it. Our backend team will then verify the information and activate your account."
      />
      <Divider/>
      <Dropdown
        question="Who can see my products?"
        answer="Nearby shops and your local area customers within 15kms radius can see your products."
      />
      <Divider/>
      <Dropdown
        question="What is LocSto app"
        answer="LocSto is a free application for customer. Customer can find product they wish from their nearby local store with in 15 kms radius."
      />
      <Divider/>
    </div>
    </div>
    </div>
    </div>
  );
}

export default Faq;
